<template>
    <div class="footer">
        <div class="footer_main flex">
            <div class="footer_main_friendship">
                <div class="footer_main_friendship_head">友情连接</div>
                <ul class="footer_main_friendship_list flex">
                    <li>
                        <ul class="footer_main_friendship_ul">
                            <li
                                v-for="(item,index) in link1"
                                :key="index"
                                @click="Jump(item)"
                            >{{item.linkname}}</li>
                        </ul>
                    </li>
                    <li>
                        <ul class="footer_main_friendship_ul">
                            <li
                                v-for="(item,index) in link2"
                                :key="index"
                                @click="Jump(item)"
                            >{{item.linkname}}</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div class="footer_main_company footer_main_friendship">
                <div class="footer_main_friendship_head">{{info.company}}</div>
                <!-- <ul class="footer_main_friendship_list flex">
                    <li>
                        <ul class="footer_main_friendship_ul">
                            <li>
                                <span>电话：</span>
                                <span>{{info.mobile || ''}}</span>
                            </li>
                            <li>
                                <span>地址：</span>
                                <span>{{info.address || ''}}</span>
                            </li>
                        </ul>
                    </li>
                </ul> -->
                <ul class="footer_main_company_list flex">
                    <li>
                        <div class="footer_main_company_list_img">
                            <img :src="info.gzh_image" alt />
                        </div>
                        <div class="footer_main_company_list_name center">{{ infos.gzh_str }}</div>
                    </li>
                    <li>
                        <div class="footer_main_company_list_img">
                            <img :src="info.xcx_image" alt />
                        </div>
                        <div class="footer_main_company_list_name center">{{ infos.xcx_str }}</div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            info: {},
            link1: [],
            link2: [],
            infos:{},//
        };
    },
    created() {
        this.getInfo();
    },
    methods: {
        Jump(item) {
            window.open(item.linkurl);
        },
        getInfo() {
            this.$axios.post("/api/common/company").then((res) => {
                if (res.code == 1) {
                    this.info = res.data;
                }
            });
            this.$axios.post('/api/index/get_company').then(res=>{
                console.log(res.data)
                this.infos = res.data
            })
            this.$axios.post("/api/index/link").then((res) => {
                if (res.code == 1) {
                    this.link1 = [];
                    this.link2 = [];
                    if (res.data.length) {
                        for (let i = 0; i < res.data.length; i++) {
                            if (i % 2 == 0) {
                                this.link1.push(res.data[i]);
                            } else {
                                this.link2.push(res.data[i]);
                            }
                        }
                    }
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.footer {
    width: 100%;
    background: #2f2f2f;
    .footer_main {
        width: 1200px;
        margin: 0 auto;
        align-items: flex-start;
        padding: 50px 0 20px;
        .footer_main_friendship {
            width: 760px;
            .footer_main_friendship_head {
                color: #ffffff;
                font-size: 14px;
                padding-bottom: 10px;
                position: relative;
                &::after {
                    position: absolute;
                    width: 25px;
                    height: 1px;
                    content: "";
                    bottom: 0px;
                    left: 0;
                    background: #ffffff;
                }
            }
            .footer_main_friendship_list {
                li {
                    margin-right: 60px;
                    .footer_main_friendship_ul {
                        li {
                            color: #ffffff;
                            font-size: 14px;
                            margin-top: 30px;
                            cursor: pointer;
                            margin-right: 0;
                            user-select: text;
                        }
                    }
                }
            }
        }
        .footer_main_company {
            width: auto;

            .footer_main_company_list {
                margin-top: 40px;
                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex-direction: column;
                    margin-right: 80px;
                    .footer_main_company_list_img {
                        width: 140px;
                        height: 140px;
                        img {
                            border-radius: 3px;
                        }
                    }
                    .footer_main_company_list_name {
                        width: 140px;
                        text-align: center;
                        color: #ffffff;
                        font-size: 12px;
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
</style>
