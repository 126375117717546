// eslint-disable-next-line no-unused-vars
import router from '../router/index'
import store from "../store/index";
import globalFn from "../utils/globalFn"
import axios from 'axios'


axios.interceptors.request.use(function (config) {
	const token = sessionStorage.getItem('token') || ''
	if (config.url != '/api/upload/upload_one_image' && config.url != '/api/study/faceAuth') {
		if (sessionStorage.getItem('token')) {
			config.data = {
				token: token,
				...config.data
			}
		}
	}
	config.headers.common["token"] = token;
	// Do something before request is sent
	return config;
}, function (error) {
	// Do something with request error
	return Promise.reject(error);
});
axios.interceptors.response.use((response) => {
	// Do something with response data
	if (response.data.code === 401) {
		store.commit('changeNoodeLogin', true);
		sessionStorage.removeItem('token');
		sessionStorage.removeItem('userInfo');
		router.push({path:'/login'})
	}
	if (response.data.code === 402) {
		globalFn.message(response.data.msg)
		// store.commit('changeNoodeRenzheng', true)
		// return
	}
	if (response.data.code === 0) {
		globalFn.message(response.data.msg)
	}
	return response.data;
}, function (error) {
	// Do something with response error
	return Promise.reject(error);
});
// axios.defaults.baseURL = 'https://hnqkx.zzwangxiao.com';
// axios.defaults.baseURL = 'https://qkx2022.ysxapp.cn';
axios.defaults.baseURL = 'https://admins.zzwangxiao.com';

if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = 'https://admins.zzwangxiao.com';
}

export default axios
