<template>
    <div id="app">
        <!-- <Header /> -->
        <router-view />
        <!-- <Footer /> -->
        <!-- <login ref="login"></login> -->
        <!-- <authentication ref="authentication"></authentication> -->
    </div>
</template>
<script>
// import login from "@/components/login";
// import authentication from "@/components/authentication";
export default {
    components: {
        // login,
        // authentication,
    },
    watch: {
        // needLogin() {
        //     if (this.needLogin) {
        //         this.$refs.login.show();
        //     }
        // },
        // needRenzheng() {
        //     if (this.needRenzheng) {
        //         this.$refs.authentication.show();
        //     }
        // },
        $route() {
            this.backtop();
        },
    },
    created() { },
    mounted() {
        // this.checkCookie();
    },
    computed: {
        // needLogin() {
        //     return this.$store.getters.needLogin;
        // },
        // needRenzheng() {
        //     return this.$store.getters.needRenzheng;
        // },
    },
    methods: {
        setCookie(cname, cvalue, exdays) {
            var d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            var expires = "expires=" + d.toGMTString();
            document.cookie = cname + "=" + cvalue + "; " + expires;
        },
        getCookie(cname) {
            var name = cname + "=";
            var ca = document.cookie.split(";");
            console.log(ca);
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i].trim();
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        },
        // 检测 cookie 有的话关闭页面
        checkCookie() {
            var user = this.getCookie("username");
            console.log("user", user);
            if (user != "") {
                console.log("有cookie");
                window.close();
            } else {
                console.log("没有cookie");
                user = "https://hnqkx.zzwangxiao.com/pc/#";
                if (user != "" && user != null) {
                    this.setCookie("username", user, 30);
                }
            }
        },
    },
};
// window.onbeforeunload = function () {
//     document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
// };
</script>

<style lang="scss">
@import "./assets/css/globalCss.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -khtml-user-select: none;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

ul,
li {
    list-style: none;
}

input {
    outline: none;
    border: none;
    box-sizing: border-box;
    background: transparent;
}

input::-webkit-input-placeholder {
    color: #999999;
    font-size: 16px;
}

input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #999999;
    font-size: 16px;
}

input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #999999;
    font-size: 16px;
}

input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #999999;
    font-size: 16px;
}

.plate-center {
    width: 1200px;
    margin: 0 auto;
}</style>
