import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home/Home.vue";
// import { Message } from 'element-ui';
Vue.use(VueRouter);

const routes = [
	// {
	// 	path: '/',
	// 	redirect: '/home'
	// },
	// {
	// 	path: "/",
	// 	name: "Home",
	// 	component: Home
	// },

	{
		path: "/",
		name: "Layout",
		redirect:'/my/mycourses',
		component: () =>
			import(/* webpackChunkName: "about" */ "../views/layout.vue"),
		children: [
			// {
			// 	path: "/my/mycourses",
			// 	// name: "Home",
			// 	// component: Home
			// 	component: () => import("../views/circles/mycourses")
			// },

			//  公告详情
			{
				path: "/noticeMessage",
				name: "NoticeMessage",
				component: () => import("../views/Home/NoticeMessage.vue")
			},
			{
				path: "/course",
				name: "Course",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/Home/Course.vue"
					)
			},
			{
				path: "/courseMessage",
				name: "CourseMessage",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/Home/CourseMessage.vue"
					)
			},
			{
				path: "/customization",
				name: "Customization",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/Home/Customization.vue"
					)
			},
			{
				path: "/customizationMessage",
				name: "CustomizationMessage",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/Home/CustomizationMessage.vue"
					)
			},
			{
				path: "/Intangible",
				name: "Intangible",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/Intangible"
					)
			}, //Intangible 非遗专区
			// my
			{
				path: "/my",
				name: "my",
				component: () =>
					import(
						/* webpackChunkName: "about" */ "../views/circles/my"
					),
				children: [
					{
						path: "realname", // 实名认证
						name: "realname",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/realname"
							)
					},
					{
						path: "mycourses", // 我的课程
						name: "mycourses",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/mycourses"
							)
					},
					{
						path: "archives", // 我的档案
						name: "archives",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/archives"
							)
					},
					{
						path: "practice", // 我的练习
						name: "practice",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/practice"
							)
					},
					{
						path: "message", // 客服
						name: "message",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/message"
							)
					},
					{
						path: "archives/chapter", // 我的档案章节详情
						name: "chapter",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/chapter"
							)
					},
					{
						path: "archives/chapter/viewPhotos", // 我的档案章节详情 查看照片
						name: "viewPhotos",
						component: () =>
							import(
								/* webpackChunkName: "about" */ "../views/circles/viewPhotos"
							)
					}
				]
			}, //学习中心
			{
				path: "/heleCenter",
				name: "heleCenter",
				component: () => import("../views/helpCenter")
			},
			// 文本说明页面
			{
				path: "/RichTextDetails",
				name: "RichTextDetails",
				component: () => import("../views/RichTextDetails")
			},
			//  新闻资讯
			{
				path: "/news",
				name: "news",
				component: () => import("../views/news")
			},
			//  关于我们
			{
				path: "/about",
				name: "about",
				component: () => import("../views/about")
			},
			{
				path: "/IntanDetails",
				name: "IntanDetails",
				component: () => import("../views/IntanDetails")
			}, //非遗详情
			{
				path: "/artisan",
				name: "artisan",
				component: () => import("../views/artisan")
			}, //工匠详情
			{
				path: "/search",
				name: "search",
				component: () => import("../views/search")
			}, //搜索
			{
				path: "/courseDetails",
				name: "courseDetails",
				component: () => import("../views/courseDetails")
			}, //课程详情
			{
				path: "/exam",
				name: "exam",
				component: () => import("../views/exam")
			}, //考试
			{
				path: "/bank",
				name: "bank",
				component: () => import("../views/bank")
			} //题库
		]
	},

	//登录
	{
		path: "/login",
		name: "Login",
		component: () => import("../views/Login")
	}

	// {
	// 	path: '/search',
	// 	name: 'Search',
	// 	component: () => import( /* webpackChunkName: "about" */ '../views/Home/Search.vue')
	// },
];

const router = new VueRouter({
	// mode: 'history',
	base: process.env.BASE_URL,
	routes
});
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
	if (to.path == "/bank") {
		// window.open('http://51.zzwangxiao.com/product/0')
		return;
	}
	next();
	// ...
});
export default router;
