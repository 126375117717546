<template>
    <div class="header">
        <div class="header_bj"></div>
        <div class="header_top flex align_center">
            <div class="header_top_logo" style="margin-right: 60px;">
                <span>河南省职业教育专业建设在线学习平台</span>
                <span class="yh-lh30">Henan Province vocational education professional construction of online learning platform</span>
            </div>
            <div class="header_top_right flex">
                <div class="header_top_ipt">
                    <div class="header_top_ipt_top flex">
                        <input
                            type="text"
                            v-model="searchVal"
                            @keyup.enter="goToSearch"
                            placeholder="请输入关键词"
                        />
                        <div
                            class="header_top_ipt_top_ss align_center flex flex_center"
                            @click="goToSearch"
                        >搜索</div>
                    </div>
                    <div class="header_top_ipt_bot flex">
                        <div class="hot">热词：</div>
                        <ul class="list flex">
                            <li
                                v-for="(item,index) in list"
                                :key="index"
                                @click="handleHotWords(item)"
                            >{{item}}</li>
                        </ul>
                    </div>
                </div>
                <!-- <div class="header_top_right_phone yh-lh40">{{info.title}}：{{info.value}}</div> -->
            </div>
        </div>
        <ul class="header_nav flex">
            <!-- <router-link
                tag="li"
                :to="item.checked ? null : item.link"
                class="align_center flex_center flex"
                v-for="(item,index) in navList"
                :key="index"
            >{{item.name}}</router-link> -->
            <li :class="{'active':!item.checked}" @click="toLink(item)" class="align_center flex_center flex"
                v-for="(item,index) in navList"
                :key="index">{{item.name}}</li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return {
            navList: [
                // { link: "/", name: "首页",checked:true },
                // { link: "/course", name: "课程中心",checked:true },
                // { link: "/customization", name: "定制课程",checked:true },
                // { link: "/Intangible", name: "非遗专区",checked:true },
                // { link: "/bank", name: "精品课程",checked:true },
                // { link: "/exam", name: "在线考试",checked:true },
                { link: "/my/mycourses", name: "学习中心",checked:false },
                // { link: "/heleCenter", name: "帮助中心",checked:true },
                // { link: "/news", name: "新闻资讯",checked:true },
                // { link: "/about", name: "关于我们",checked:true },
            ],
            info: {},
            list: [],
            searchVal: "", // 搜索的值
        };
    },
    created() {
        this.getInfo();
    },
    computed: {
        vuexSearchVal() {
            return this.$store.getters.getSeachValue;
        },
    },
    watch: {
        vuexSearchVal(e) {
            this.searchVal = e;
        },
    },
    methods: {
        //跳转
        toLink (item) {
            if (item.checked || location.hash == '#/my/mycourses') return
            this.$router.push(item.link);
        },
        // 搜索
        goToSearch() {
            if (this.$route.path != "/search") {
                this.pageJumps("search");
                this.$store.commit("upSearchValue", this.searchVal);
            } else {
                this.$store.commit("upSearchValue", this.searchVal);
            }
        },
        // 点击热词
        handleHotWords(item) {
            this.searchVal = item;
            this.goToSearch();
        },
        getInfo() {
            this.$axios.post("/api/common/serviceHotline").then((res) => {
                if (res.code == 1) {
                    this.info = res.data;
                }
            });
            this.$axios.post(this.$api.hotlink).then((res) => {
                if (res.code == 1) {
                    this.list = res.data;
                }
            });
        },
    },
};
</script>

<style scoped lang="scss">
.header {
    position: relative;
    .header_bj {
        position: absolute;
        left: 0;
        width: 100%;
        top: 0;
        background: url("../assets/image/toubu-bj.png") no-repeat;
        background-size: cover;
        background-position: center;
        height: 210px;
        z-index: 0;
    }
    .header_top {
        position: relative;
        width: 1200px;
        height: 150px;
        margin: 0 auto;
        .header_top_logo {
            display: flex;
            flex-direction: column;
            span {
                &:first-child {
                    color: #1263ff;
                    font-size: 36px;
                    font-weight: bold;
                    font-family: Microsoft YaHei;
                }
                &:last-child {
                    color: #333333;
                    font-size: 15.5px;
                }
            }
        }
        .header_top_right {
            .header_top_ipt {
                display: flex;
                align-items: flex-end;
                flex-direction: column;
                .header_top_ipt_top {
                    width: 315px;
                    background: #fff;
                    border-radius: 20px;
                    margin-bottom: 10px;
                    border: 1px solid #1263ff;
                    border-right: none;
                    input {
                        width: 100%;
                        height: 40px;
                        display: block;
                        list-style: none;
                        outline: none;
                        font-size: 14px;
                        padding: 0 20px;
                    }
                    .header_top_ipt_top_ss {
                        width: 75px;
                        height: 40px;
                        flex-shrink: 0;
                        color: #ffffff;
                        font-size: 18px;
                        cursor: pointer;
                        background: #1263ff;
                        border-radius: 0 20px 20px 0;
                    }
                }
                .header_top_ipt_bot {
                    .hot {
                        color: #999999;
                        font-size: 14px;
                    }
                    .list {
                        li {
                            cursor: pointer;
                            color: #999999;
                            font-size: 14px;
                            margin-right: 15px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .header_top_right_phone {
                color: #1263ff;
                font-size: 20px;
                font-weight: bold;
                margin-left: 60px;
                user-select: text;
            }
        }
    }
    .header_nav {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        background: #1263ff;
        li {
           
            flex: 1;
            cursor: pointer;
            color: #ffffff;
            font-size: 18px;
            height: 60px;
            padding: 0 20px;
        }
        .active,
        .router-link-active {
            font-weight: bold;
            background: #0e57e1;
            border-radius: 4px 4px 0px 0px;
        }
    }
}
</style>
